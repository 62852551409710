import React from "react";
import styled, { keyframes } from "styled-components";
import Waypoint from "react-waypoint";
import PropTypes from "prop-types";
import { fadeInRight, fadeOutLeft } from "react-animations";
import Card from "./Card";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 3% 0;
  animation: ${(props) =>
      props.active ? keyframes`${fadeInRight}` : keyframes`${fadeOutLeft}`}
    1s;
`;

const Projects = [
  {
    title: `Vyro`,
    description: `Vyro is an innovative startup in the automotive industry, providing seamless software managing EV sales and services.`,
    link: "https://www.vyro.com.au/",
  },
  {
    title: `Honeywell | Sine`,
    description: `Startup to Enterprise Leading Visitor Management Software. Sine helped businesses of all sizes manage visitors, contractors, couriers, and employees.`,
    link: "https://www.glassdoor.com.au/Overview/Working-at-Sine-EI_IE2534979.11,15.htm",
  },
  {
    title: `SAYI.DO`,
    description: ` Sayido  is a wedding planning platform that helps couples plan their wedding with ease. It provides a platform for couples to plan their wedding, invite guests, and manage their RSVPs all in one place`,
    link: "https://sayi.do/",
  },
];

const Cards = Projects.map((project) => (
  <Card
    title={project.title}
    description={project.description}
    link={project.link}
    githublink={project.githublink}
    key={project.title}
  />
));

const Layout = (props) => (
  <Waypoint onEnter={props.onEnter} onLeave={props.onLeave}>
    <Wrapper active={props.active}>
      <>{Cards}</>
    </Wrapper>
  </Waypoint>
);

Layout.propTypes = {
  active: PropTypes.bool.isRequired,
  onEnter: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
};

export default Layout;
