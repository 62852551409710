export const firebase = {
  apiKey: "AIzaSyBd06BgSc96o5exaIzuvyoFvfxFBBZQ9-M",
  authDomain: "raj-c-k.firebaseapp.com",
  databaseURL: "https://raj-c-k.firebaseio.com",
  projectId: "raj-c-k",
  storageBucket: "raj-c-k.appspot.com",
  messagingSenderId: "1008241574479",
  appId: "1:1008241574479:web:e3ddadd007f61ea351a03a",
  measurementId: "G-MFH1J4Y1VV",
};

export const reduxFirebase = {
  userProfile: "users",
  useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
  enableLogging: false,
};

export default { firebase, reduxFirebase };
