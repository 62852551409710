import React from "react";
// import styled from 'styled-components';



const MediumClap  = props => {


    return (
        <div>
            Medium Clap
            </div>

    )
}

export default MediumClap

