import React from "react";
import PropTypes from "prop-types";
import { bounceInLeft } from "react-animations";
import styled, { keyframes } from "styled-components";
import { Link } from "react-scroll";

const Wrapper = styled.p`
  width: 70%;
  font-size: 22px;
  text-align: left;
  text-indent: 20px;
  max-width: 1100px;
  padding: 20px;
  line-height: 1.7;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);

  border-radius: 14px;
  transition: 0.2s;
  animation: ${(props) => (props.active ? keyframes`${bounceInLeft}` : "")} 1s;
  &:hover {
    transform: scale(1.009);
  }

  @media (max-width: 876px) {
    width: 100%;
    order: 1;
    padding: 30px;
  }
`;

const SkillsetLink = styled.span`
  color: dodgerblue;
  cursor: pointer;
`;

const Paragraph = (props) => (
  <Wrapper active={props.active}>
    <strong>Full-stack developer</strong> with over <strong>8</strong> years of
    experience with <strong>Typescript,</strong>{" "}
    <strong>Javascript, Python </strong> and<strong> Java</strong>. I enjoy
    building <strong>scalable</strong> and <strong>maintainable</strong>{" "}
    codebases.
    <br />
    Currently at{" "}
    <a
      href="https://www.vyro.com.au/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "dodgerblue" }}
    >
      Vyro
    </a>{" "}
    in the EV industry, applying <strong>agile</strong> practices to orchestrate
    highly available and scalable web solutions.{" "}
    <Link to="skillset" spy={true} smooth={true}>
      <SkillsetLink>See Skills</SkillsetLink>
    </Link>
    ,
    <a
      href="https://drive.google.com/file/d/1FcdliBLcRrUz9PZw8iI3epW8vNLySIvG/view?usp=sharing"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "dodgerblue" }}
    >
      {" "}
      resume,
    </a>{" "}
    and
    <a
      href="https://github.com/rajivaux"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "dodgerblue" }}
    >
      {" "}
      github.
    </a>{" "}
  </Wrapper>
);

Paragraph.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default Paragraph;
